<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="query.status"
          placeholder="柜子状态"
          @change="getList(1)"
          clearable
        >
         
          <el-option
            v-for="(item, index) in this.const.STATION_STATUS"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.connectionStatus"
          placeholder="柜子连接状态"
          @change="getList(1)"
          clearable
        >
         
          <el-option
            v-for="(item, index) in this.const.STATION_ONLINE"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

  
      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
      <el-button
        class="but"
        type="primary"
        @click="showAdd"
        v-auth="this.per.STATION_ADD"
        >添加站点</el-button
      >
    </div>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="站点名称" prop="stationName"></el-table-column>
      <el-table-column label="站点编号" prop="stationMacId"></el-table-column>
      <el-table-column label="仓门数" prop="maxNum"></el-table-column>
      <el-table-column label="充电预留仓位" prop="reserveDoor"></el-table-column>
      <el-table-column label="连接状态" prop="workStatus">
        <template #default="scope">
          <eb-badge
            :target="scope.row.workStatus"
            :list="this.const.STATION_ONLINE"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="使用状态" prop="stopStatus">
        <template #default="scope">
          <eb-badge
            :target="scope.row.stopStatus"
            :list="this.const.STATION_STATUS"
          ></eb-badge>
        </template>
      </el-table-column>

      <!-- <el-table-column label="站点地址" prop="address">
        <template #default="scope">
          <span>
            {{ scope.row.province }}-{{ scope.row.city }}-{{
              scope.row.area
            }}-{{ scope.row.address }}
          </span>
        </template>
      </el-table-column> -->

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEdit(scope.row)"
            v-auth="this.per.STATION_UPDATE"
          >
            编辑
          </span>

           <span
            class="option option-danger"
            @click="doDel(scope.row)"
            v-auth="this.per.STATION_UPDATE"
          >
            删除
          </span>

          <span
            v-if="scope.row.status == this.const.STATION_STATUS_CODE.DISABLE"
            class="option option-primary"
            @click="
              doUpdateStatus(scope.row, this.const.STATION_STATUS_CODE.ABLE)
            "
            v-auth="this.per.STATION_DEL"
          >
            启用
          </span>

          <span
            v-else
            class="option option-danger"
            @click="
              doUpdateStatus(scope.row, this.const.STATION_STATUS_CODE.DISABLE)
            "
            v-auth="this.per.STATION_DEL"
          >
            禁用
          </span>

          <!-- <span class="option option-primary">设置音量</span> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitStation"
      :agentList="agentList"
    ></EditModal>

    <MapModal
      :coord="coord"
      :ref="MODAL_KEY.MAP_MODAL"
      :modalData="modalData"
    ></MapModal>
  </div>
</template>
 
<script>
import {
  fetchStationList,
  addStation,
  updateStation,
  updateStationStatus,
  delStation
} from "@/api/station";
import EditModal from "./component/EditModal";
import MapModal from "./component/MapModal";
import { mapState } from "vuex";
export default {
  components: { EditModal, MapModal },
  computed: mapState({
    agentList(state) {
      return state.agent.agentList.list || [];
    },
  }),

  data() {
    return {
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      agentMap: {},
      query: {
        status: 1,
      },

      modalData: {},
      modalType: "add",
      coord: {
        latitude: "",
        longitude: "",
      },
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
        MAP_MODAL: "MAP_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    doUpdateStatus(data, status) {
      updateStationStatus({ stationMacId: data.stationMacId, status }).then(
        (res) => {
          this.$message.success("操作成功！");
          this.getList();
        }
      );
    },

    goDetail(id) {
      this.$router.push("/station/detail/" + id);
    },

    showMap(e) {
      this.coord = {
        longitude: e.longitude,
        latitude: e.latitude,
      };
      this.modalData = e;
      this.$refs[this.MODAL_KEY.MAP_MODAL].showModal();
    },

    showAdd() {
      this.modalData = {
        stationImg:''
      };
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    showEdit(data) {
      this.modalData = {
        stationMacId: data.stationMacId
      }
      this.modalType = "edit";
     
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal(this.modalData.stationMacId);
    },

    doDel(row) {
      this.$confirm("是否要删除站点", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        delStation({ stationMacId: row.stationMacId }).then((res) => {
          this.$message.success("删除成功");
          this.getList();
        });
      });
    },

    submitStation(data) {
      let request = updateStation;
      let msg = "编辑站点成功";
      if (this.validatenull(data.id)) {
        request = addStation;
        msg = "添加站点成功";
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchStationList({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records || [];
        this.total = res.data.data.total;
      });
    },

    resetPage() {
      this.dataSource = [];
      this.pages = {
        pageIndex: 1,
        pageSize: 10,
      };
      this.agentMap = {};
      this.query = {};
    },
  },

  beforeRouteEnter(to, from, next) {
    if (from.fullPath.indexOf("detail") > -1) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },

  activated() {
    if (!this.$route.meta.isBack || this.dataSource.length === 0) {
      this.resetPage();
      let result = this.$store.dispatch("fetchAgentListAll");
      result.then((res) => {
        this.agentList.map((item) => {
          this.agentMap[item.id] = item.operationName;
        });
        this.getList(1);
      });
    }
  },
};
</script>
